.privacy {
  &-table {
    &--fixed {
      table-layout: fixed;
    }
    th,
    td {
      display: table-cell;
      width: auto;
    }
  }
}
.sitemap-list {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  &__item {
    white-space: nowrap;
    &:not(:last-child):after {
      margin: 0 .5em;
      content: '/';
    }
  }
}

.sitemap {
  @mixin sitemap-ico {
    margin-left: 5px;
    fill: #666;
  }
  &__ico-mail {
    @include sitemap-ico;
  }
  &__ico-blank {
    @include sitemap-ico;
    margin-top: -5px;
  }
}
.purchaserequestform .logo-header {
  background: #ffffff;
  padding: 20px 0;
  text-align: center;
  @include mq(touch) {
    display: none;
  }
}
.purchaserequestform .main-visual {
  background: url(/assets/img/purchaserequestform/bg_mv.jpg) no-repeat 50% 0;
  text-align: center;
}

.purchaserequestform2 {
  &-main-visual {
    height: 700px;
    background: url(../img/purchaserequestform2/main_bg.png) no-repeat center/cover;
    @media screen and (max-width: 1300px) {
      height: auto;
      background: none;
    }
  }
  &-only-pc {
    display: block;
    margin: 0 auto;
    @media screen and (max-width: 1300px) {
      display: none;
    }
  }
  &-only-sp {
    display: none;
    @media screen and (max-width: 1300px) {
      display: block;
      width: 100%;
    }
  }
  &-img01 {
    margin: 16px 0 74px;
    @include mq(touch) {
      margin: 4vw 0 10vw;
    }
  }
  &-img03 {
    margin-bottom: 30px;
    @include mq(touch) {
      margin-bottom: 7.5vw;
    }
  }
  &-tel-button {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
    transition: opacity .6s;
    a{
      display: block;
      transition: .3s;
      img {
        width: 100%;
      }
    }
  }
}
.active {
  visibility: visible;
  opacity: 1;
}
