.used-documents {
  &__box {
    padding: 4vw;
    background: #FFF;
    @include mq() {
      padding: 40px 46px 30px;
    }
  }
  &__attention {
    margin-top: 5vw;
    line-height: 1.5;
    @include mq() {
      margin-top: 40px;
    }
  }
}
.used-cards {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin-top: -6vw;
  @include mq() {
    margin-top: -34px;
  }
  &__item {
    text-align: center;
    @include mq(sp) {
      flex-basis: 48%;
      max-width: 48%;
      margin-top: 6vw;
      &:nth-child(2n) {
        margin-left: 4%;
      }
    }
    @include mq() {
      flex-basis: 33%;
      max-width: 33%;
      margin-top: 34px;
    }
    @include mq(lg) {
      flex-basis: 25%;
      max-width: 25%;
    }
  }
  &__txt {
    margin-top: 2vw;
    font-weight: bold;
    font-size: 3.4rem;
    line-height: 1.4;
    @include mq() {
      margin-top: 13px;
      font-size: 1.8rem;
    }
    small {
      font-size: 78%;
    }
  }
}