.tire {
  &-content {
    margin-top: 8vw;
    @include mq() {
      margin-top: 40px;
    }
  }
  &-ttl {
    color: #031853;
    font-weight: bold;
    font-size: 4.0rem;
    text-align: center;
    line-height: 1;
    @include mq() {
      font-size: 3.0rem;
    }
    &__inner {
      display: inline-block;
      padding-bottom: 3px;
      border-bottom: 5px solid #c00;
      @include mq() {
        padding-bottom: 8px;
      }
    }
  }
  &-troubles {
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
    }
    &__item {
      margin-top: 5vw;
      @include mq(sp) {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
      }
      @include mq() {
        flex-basis: 31.25%;
        max-width: 31.25%;
        margin-top: 40px;
        & + & {
          margin-left: 3.125%;
        }
      }
    }
    &__box {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 3vw 0;
      background: #FFF;
      border-radius: 10px;
      font-size: 3.0rem;
      font-weight: bold;
      line-height: 1.3;
      @include mq(sp) {
        flex-basis: 45%;
      }
      @include mq() {
        padding: 37px 0;
        font-size: 1.8rem;
      }
      @include mq(lg) {
        font-size: 2.3rem;
      }
      &--red {
        border: 3px solid $red;
        color: $red;
        font-size: 3.2rem;
        @include mq() {
          border-width: 5px;
          font-size: 2.0rem;
        }
        @include mq(lg) {
          font-size: 2.4rem;
        }
      }
    }
    &__ico {
      fill: $red;
      @include mq(sp) {
        flex-basis: 15%;
      }
      @include mq() {
        margin-right: 15px;
      }
    }
    &__txt {
      @include mq(sp) {
        margin-left: 5%;
        flex-basis: 70%;
      }
    }
    &__arrow {
      display: block;
      margin: 0 auto;
      text-align: center;
      @include mq(sp) {
        flex-basis: 6%;
        margin: 0 2%;
        transform: rotate(-90deg) translate(50%, 50%);
        transform-origin: bottom;
      }
      @include mq() {
        margin: 23px auto 22px;
      }
    }
  }
}