@charset "UTF-8";

//settings
@import "./settings/minireset";
@import "./settings/fonts";
@import "./settings/variables";
@import "./settings/mixins";
@import "./settings/animations";
@import "./settings/setting";

//layout
@import "./layout/base";
@import "./layout/header";
@import "./layout/nav";
@import "./layout/mega-menu";
@import "./layout/footer";

//plugin
@import "./plugin/slick";
@import "./plugin/slick-theme";
@import "./plugin/lightbox";

//module
@import "./modules/ttl";
@import "./modules/btn";
@import "./modules/list";
@import "./modules/grid";
@import "./modules/table";
@import "./modules/block";
@import "./modules/module";
@import "./modules/flow";
@import "./modules/news";
@import "./modules/form";
@import "./modules/other";

//style
@import "./pages/top";
@import "./pages/company";
@import "./pages/sellcar";
@import "./pages/used";
@import "./pages/tire";
@import "./pages/other";

//タイヤと共通css
@import "./plugin/magnific-popup";

//既存スタイルの上書き用
@import "./overwhite/overwhite";

.mfp-hide {
  display: none;
}
