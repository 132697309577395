html {
  background: #FFF;
  font-size: calc(100vw / 96);
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  @include mq() {
    font-size: 62.5%;
  }
}
body {
  color: $font-color-default;
  font-size: 3.0rem;
  font-family: $font-family-default;
  font-weight: 300;
  line-height: 2.14;
  @include mq() {
    font-size: 1.4rem;
  }
  @include mq(lg) {
    padding-left: 200px;
  }
  &.preload * {
    transition: 0s !important;
  }
}

img {
  max-width: 100%;
  vertical-align: bottom;
  backface-visibility: hidden;
}
svg {
  max-width: 100%;
  vertical-align: middle;
  backface-visibility: hidden;
}

a {
  text-decoration: none;
}
.alpha-img {
  img {
    transition: $transition;
  }
  &:hover {
    img {
      opacity: .6;
    }
  }
}
input:not([type="radio"]):not([type="checkbox"]),
button,
select,
textarea {
  -webkit-border-radius: 0;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-appearance: none;
  appearance: none;
  font-family: inherit;
}

.wrapper {
  padding-top: 14.58vw;
  @include mq() {
    padding-top: 70px;
  }
  @include mq(lg) {
    padding-top: 0;
  }
}

.inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: {
    left: 3.125vw;
    right: 3.125vw;
  };
  @include mq() {
    padding: {
      left: 20px;
      right: 20px;
    };
  }
  &--sm {
    max-width: 700px;
    margin: 0 auto;
  }
  &--lg {
    max-width: 1260px;
  }
  &--free {
    max-width: none;
  }
}

.is-hide {
  display: none !important;
}

.only {
  &-pc,
  &-touch,
  &-sp {
    display: none;
  }
  &-pc {
    @include mq() {
      display: block;
    }
  }
  &-touch {
    @include mq(touch) {
      display: block;
    }
  }
  &-sp {
    @include mq(sp) {
      display: block;
    }
  }
}