.flow {
  position: relative;
  padding-left: 12vw;
  @include mq() {
    margin-top: 28px;
    padding-left: 0;
  }
  @mixin ico-ball {
    position: absolute;
    width: 14px;
    height: 14px;
    border-radius: 100%;
    background: $base-blue;
  }
  &__border {
    position: absolute;
    top: 0;
    left: 4vw;
    width: 2px;
    height: 100%;
    background: $base-blue;
    @include mq() {
      left: 50%;
      margin-left: -1px;
    }
    &:before,
    &:after {
      @include ico-ball;
      left: 0;
      margin-left: -6px;
      content: '';
    }
    &:before {
      top: 0;
      margin-top: -7px;
    }
    &:after {
      bottom: 0;
      margin-bottom: -7px;
    }
  }
  &__list {
    @extend .clearfix;
    list-style-type: none;
    @include mq() {
      
    }
  }
  &__item {
    position: relative;
    background: #FFF;
    @include mq() {
      width: 40.625%;
      &:nth-child(even) {
        margin-left: auto;
      }
    }
    & + & {
      margin-top: 6vw;
      @include mq() {
        margin-top: -100px;
      }
      @include mq(lg) {
        margin-top: -135px;
      }
    }
    &:before,
    &:after {
      content: '';
    }
    &:before {
      @include ico-ball;
      left: 0;
      margin-top: -7px;
      @include mq(sp) {
        top: 25vw;
        margin-left: calc(-8vw - 6px);
        
      }
      @include mq() {
        top: 140px;
        @at-root .flow__item:nth-child(odd):before {
          left: calc(100% - 7px);
          margin-left: 23.07%;
        }
        @at-root .flow__item:nth-child(even):before {
          left: -7px;
          margin-left: -23.07%;
        }
      }
    }
    &:after {
      position: absolute;
      height: 2px;
      background: $base-blue;
      @include mq(sp) {
        top: 25vw;
        right: 100%;
        width: 8vw;
      }
      @include mq() {
        top: 140px;
        width: 23.07%;
        @at-root .flow__item:nth-child(odd):after {
          left: 100%;
        }
        @at-root .flow__item:nth-child(even):after {
          right: 100%;
        }
      }
    }
  }
  &__img img {
    width: 100%;
  }
  &__content {
    display: table;
    min-height: 20vw;
    @include mq() {
      height: 110px;
      min-height: auto;
    }
    @include mq(lg) {
      height: 80px;
    }
    & > div {
      display: table-cell;
      vertical-align: middle;
    }
  }
  &__number {
    @extend .font-advent;
    width: 20vw;
    background: $base-blue;
    color: #FFF;
    font-size: 6.0rem;
    font-weight: bold;
    text-align: center;
    @include mq() {
      width: 80px;
      font-size: 3.0rem;
    }
  }
  &__txt {
    padding: 3vw;
    line-height: 1.43;
    @include mq() {
      padding: 0 20px;
    }
  }
  &-grid {
    @include mq() {
      margin-top: -40px;
    }
    &__item {
      padding: 4vw;
      @include mq() {
        margin-top: 40px;
        padding: 20px 20px 12px;
      }
      & + & {
        margin-top: 5vw;
        @include mq() {
          margin-top: 40px;
        }
      }
    }
    &__caption {
      margin-top: 3vw;
      @include mq() {
        margin-top: 12px;
      }
    }
  }
}