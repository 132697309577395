.header {
  position: fixed;
  left: 0;
  top: 0;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 14.58vw;
  background: #FFF;
  z-index: 100;
  @include mq() {
    height: 70px;
  }
  @include mq(lg) {
    display: block;
    width: 200px;
    height: 100vh;
  }
  &-logo {
    display: block;
    flex-basis: 21.04vw;
    margin-left: 3.125vw;
    @include mq() {
      flex-basis: 100px;
    }
    @include mq(lg) {
      width: 158px;
      margin: 47px auto 43px;
    }
  }
  &-trigger {
    position: relative;
    flex-basis: 14.58vw;
    height: 14.58vw;
    background: $base-blue;
    cursor: pointer;
    @include mq() {
      flex-basis: 70px;
      height: 70px;
    }
    @include mq(lg) {
      display: none;
    }
    &__bar {
      position: absolute;
      left: 25%;
      width: 50%;
      height: 3.57%;
      background: #FFF;
      transition: $transition;
      &:nth-child(1) {
        top: 34.29%;
        @at-root .nav-open & {
          transform: rotate(45deg);
        }
      }
      &:nth-child(2) {
        top: 50%;
        margin-top: -1.785%;
        @at-root .nav-open & {
          opacity: 0;
        }
      }
      &:nth-child(3) {
        bottom: 34.29%;
        @at-root .nav-open & {
          transform: rotate(-45deg);
        }
      }
      @at-root .nav-open & {
        top: 50%;
        bottom: auto;
        margin-top: -1.785%;
      }
    }
  }
}