@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}

@mixin table-cmn {
  width: 100%;
  margin-top: 30px;
  tr {
    & + tr th {
      border-top: 0;
    }
  }
  th,
  td {
    border: 1px solid #CCC;
    @include mq(sp) {
      display: block;
      width: 100%;
      padding: 3vw;
    }
    @include mq() {
      padding: 15px;
      vertical-align: middle;
    }
  }
  th {
    background: #EEE;
    @include mq() {
      width: 200px;
      text-align: center;
    }
  }
  td {
    background: #FFF;
    @include mq(sp) {
      border-top: 0;
    }
    @include mq() {
      padding: {
        right: 20px;
        left: 20px;
      };
    }
  }
}

@mixin linkTxt {
  text-decoration: none;
  color: inherit;
  &:hover,
  &:hover span {
    text-decoration: underline;
  }
}

@mixin btnArrow {
  position: relative;
  &:after {
    position: absolute;
    top: 50%;
    border-left: 7px solid #000;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    transform: translateY(-50%);
    content: '';
    @include mq() {
      border-left-width: 5px;
      border-top-width: 5px;
      border-top-bottom: 5px;
    }
  }
}

@mixin ico-list {
  position: relative;
  padding-left: 1em;
  &:before {
    position: absolute;
    left: 0;
    top: 0;
  }
}

//arrow関数を応用してarrowlinkで矢印アイコンを作る
//使用例
//@include arrowlink(5,8,#666);
@mixin arrow($direction: right, $size: 10, $color: $black) {
  $set: left;
  @if $direction==left {
    $set: right;
  }
  @else if $direction==top {
    $set: bottom;
  }
  @else if $direction==bottom {
    $set: top;
  }
  @else {
    $set: left;
  }
  $size: round($size / 2);
  width: 0;
  height: 0;
  border: $size+px solid transparent;
  border-#{$set}-color: $color;
}

@mixin arrowlink($top, $size: 10, $color: $black) {
  position: relative;
  padding-left: 10px;
  &:before {
    display: block;
    content: "";
    position: absolute;
    top: $top + px;
    left: 1px;
    @include arrow(right, $size, $color);
  }
}

//使用例
//  @include absolute(0,0,0,0);
//  @include absolute($top:0, $bottom:0);
@mixin absolute($top:null, $right:null, $bottom:null, $left:null) {
  position: absolute;
  @if $top !=null {
    top: $top;
  }
  @if $right !=null {
    right: $right;
  }
  @if $bottom !=null {
    bottom: $bottom;
  }
  @if $left !=null {
    left: $left;
  }
}
