@import url('https://fonts.googleapis.com/css?family=Advent+Pro:400,600');
@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 100;
  src: url(../fonts/NotoSerifCJKjp-ExtraLight_sub.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 200;
  src: url(../fonts/NotoSerifCJKjp-Light_sub.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/NotoSerifCJKjp-Regular_sub.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/NotoSerifCJKjp-Medium_sub.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 600;
  src: url(../fonts/NotoSerifCJKjp-SemiBold_sub.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/NotoSerifCJKjp-Bold_sub.otf) format('opentype');
}

@font-face {
  font-family: 'Noto Serif Japanese';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/NotoSerifCJKjp-Black_sub.otf) format('opentype');
}
