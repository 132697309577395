.footer {
  @extend .bg-gray;
  margin-top: -3.125vw;
  padding-top: 3.125vw;
  @include mq() {
    margin-top: -20px;
    padding-top: 20px;
  }
  &-inner {
    padding: 0 3.125vw;
    @include mq() {
      max-width: 1000px;
      padding: 0 20px;
      margin: 0 auto;
    }
  }
  &-bnr {
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
    }
    &__item {
      @include mq() {
        flex-basis: 31.875%;
      }
      & + & {
        margin-top: 4.17vw;
        @include mq() {
          margin: 0 0 0 2.1875%;
        }
      }
    }
  }
  &-icons {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    &__item {
      flex-basis: 47.8%;
      margin-top: 4.17vw;
      @include mq() {
        flex-basis: 23.43%;
        max-width: 23.43%;
        margin-top: 20px;
      }
    }
    &__link {
      display: block;
      border: 1px solid #DDD;
      background: #FFF;
      &:hover .footer-icons__txt {
        color: $base-blue;
      }
    }
    &__ico {
      background: url(../img/common/bg_stripe_blue.gif);
    }
    &__txt {
      padding: .625vw 0;
      color: $font-color-default;
      font-weight: bold;
      font-size: 2.4rem;
      @include mq() {
        padding: 2px 0;
        font-size: 1.0rem;
      }
      @include mq(lg) {
        font-size: 1.3rem;
      }
    }
    &__svg {
      fill: $font-color-default;
      @at-root a:hover & {
        fill: $base-blue;
      }
    }
  }
  &-main {
    position: relative;
    margin-top: 27.4vw;
    @include mq() {
      margin-top: 196px;
    }
    &:before {
      position: absolute;
      bottom: 100%;
      left: 0;
      width: 100%;
      height: 17vw;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 0 bottom;
      background-image: url(../img/common/bg_footer_before_sp.png);
      content: '';
      @include mq(lg) {
        height: 170px;
        background-repeat: repeat-x;
        background-position: right bottom;
        background-size: auto;
        background-image: url(../img/common/bg_footer_before_pc.png);
      }
    }
    &-menu {
      display: none;
      @include mq(lg) {
        display: table;
        width: 100%;
        background: #111;
        color: #FFF;
        font-size: 1.1rem;
        &__item {
          display: table-cell;
          vertical-align: top;
        }
        &__logo {
          width: 167px;
          text-align: center;
        }
      }
    }
    &__inner {
      @include mq() {
        padding: 40px 26px;
      }
    }
    &__logo {
      margin-bottom: 3px;
    }
  }
  &-table {
    display: table;
    width: 100%;
    background: #333;
    & > div {
      display: table-cell;
      padding: 0 25px;
      vertical-align: top;
      line-height: 1.9;
    }
    & + & > div {
      padding-top: 10px;
    }
    &:first-child > div {
      padding-top: 35px;
    }
    &:last-child > div {
      padding-bottom: 35px;
    }
    &__parent {
      width: 200px;
      background: #222;
      font-size: 1.2rem;
    }
  }
  &-nav {
    @extend .clearfix;
    &__item {
      float: left;
      &:not(:last-child):after {
        margin: 0 5px;
        content: '／';
      }
    }
    &__item-lower {
      font-size: 1.0rem;
      color: #BBB;
    }
  }
  &-copyright {
    padding: .375vw;
    background: #111;
    color: #666;
    font-size: 2.0rem;
    text-align: center;
    @include mq() {
      padding: 6px 0;
      background: #f6f6f6;
      font-size: 1.0rem;
    }
  }
}