.company {
  &-bnr {
    max-width: 1000px;
    margin: 10.5vw auto 0;
    padding-left: 3.125vw;
    padding-right: 3.125vw;
    text-align: center;
    a:hover {
      opacity: .7;
    }
    @include mq() {
      margin-top: 0;
      margin-bottom: 25px;
    }
  }
  &-table {
    width: 100%;
    th,
    td {
      border: 1px solid #CCC;
    }
    th {
      padding: 3vw;
      background: #EEE;
      @include mq() {
        width: 200px;
        padding: 15px 20px 15px 50px;
      }
    }
    td {
      padding: 3vw;
      background: #FFF;
      @include mq() {
        padding: 15px 20px 15px 38px;
      }
    }
  }
  &-box-list {
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
    }
    &__item {
      background: #FFF;
      border: 1px solid #CCC;
      @include mq() {
        flex-basis: 49%;
        max-width: 49%;
      }
      & + & {
        margin-top: 5vw;
        @include mq() {
          margin: 0 0 0 2%;
        }
      }
      &--no-border {
        border: 0;
      }
    }
    &__ttl {
      padding: 3vw 0 2vw;
      background: #EEE;
      border-bottom: 1px solid #CCC;
      text-align: center;
      @include mq() {
        padding: 16px 0 14px;
      }
    }
    &__txt {
      padding: 3vw;
      @include mq() {
        padding: 18px 24px;
      }
      li {
        padding-left: 1em;
        text-indent: -1em;
      }
    }
  }
  &-ico-window {
    margin-top: -2px;
    margin-left: 1vw;
    @include mq() {
      margin-left: 5px;
    }
  }
  &-iso-logo {
    float: right;
    width: 32vw;
    margin: 0 0 2.5vw 2.5vw;
    @include mq() {
      width: auto;
      margin: 0 0 0 10px;
    }
  }
  &-shops {
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: -20px;
    }
    &__item {
      @include mq() {
        flex-basis: 48%;
        max-width: 48%;
      }
      &--lg {
        @include mq() {
          flex-basis: 100%;
          max-width: 100%;
        }
      }
    }
    &__grid {
      @include mq() {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;
      }
      &-item {
        &:nth-child(n + 3) {
          margin-top: 3vw;
          @include mq() {
            margin-top: 0;
          }
        }
        @include mq() {
          flex-basis: 50%;
          max-width: 50%;
        }
      }
    }
    &__inner {
      padding: 4vw;
      @include mq() {
        position: relative;
        padding: 20px 20px 83px;
        @at-root .company-shops__item--lg & {
          padding-bottom: 20px;
        }
      }
    }
    &__link {
      color: $base-blue;
    }
    &__btn {
      &-area {
        margin-top: 4vw;
        @include mq() {
          margin-top: 25px;
        }
      }
    }
    &__btns {
      @include mq() {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        justify-content: center;
      }
      &-item {
        @include mq() {
          flex-basis: 48%;
        }
        & + & {
          margin-top: 3vw;
          @include mq() {
            margin: 0 0 0 4%;
          }
        }
        &--3col {
          & + & {
            @include mq() {
              margin: 0 0 0 1%;
            }
          }
        }
      }
    }
    &-gallery {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      flex-wrap: wrap;
      max-width: 460px;
      padding: 0 5px;
      &__item {
        flex-basis: 19%;
        max-width: 19%;
        margin-right: 1.25%;
        margin-top: 5px;
        cursor: pointer;
        &:nth-child(5n),
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &-organization {
    img[src$=".svg"] {
      width: 100%;
    }
  }
}