.gnav {
  position: absolute;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: $transition;
  line-height: 1;
  @include mq(touch) {
    top: 100%;
    width: 100%;
    height: calc(100vh - 14.58vw);
    padding-bottom: 10vw;
    overflow-y: auto;
    z-index: 200;
    -webkit-overflow-scrolling: touch;
    @at-root .nav-open & {
      opacity: 1;
      visibility: visible;
    }
  }
  @include mq(lg) {
    position: static;
    visibility: visible;
    opacity: 1;
    text-align: right;
  }
  &-list {
    &__item {
      @include mq(touch) {
        border-bottom: 1px solid #FFF;
        transition: .25s ease-out;
        opacity: 0;
        visibility: hidden;
        transform: translateX(50%);
        @at-root .nav-open & {
          @for $num from 1 through 6 {
            & {
              visibility: visible;
              opacity: 1;
              transform: translateX(0);
            }
            &:nth-child(#{$num}) {
              transition-delay: .25s + $num * .05s;
            }
          }
        }
      }
    }
    &__link {
      display: block;
      color: $font-color-default;
      font-weight: bold;
      @include mq(touch) {
        position: relative;
        padding: 5.72vw 5.2vw;
        color: #FFF;
        font-size: 4.0rem;
      }
      @include mq() {
        padding: 45px 20px;
        font-size: 2.0rem;
      }
      @include mq(lg) {
        padding: 13px 20px 15px 20px;
        font-size: 1.3rem;
        transition: $transition;
        &:hover {
          background: $base-blue;
          color: #FFF;
        }
      }
    }
  }
  &-sub-list {
    @include mq(touch) {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      visibility: hidden;
      transform: translateX(50%);
      transition: .25s;
      opacity: 0;
      @at-root .nav-open & {
        & {
          visibility: visible;
          opacity: 1;
          transform: translateX(0);
          transition-delay: .6s;
        }
      }
    }
    @include mq(lg) {
      margin-top: 10px;
      padding: 0 20px;
    }
    &__item {
      @include mq(touch) {
        flex-basis: 50%;
        max-width: 50%;
        border-bottom: 1px solid #FFF;
        text-align: center;
      }
      & + & {
        @include mq(touch) {
          border-left: 1px solid #FFF;
        }
        @include mq(lg) {
          margin-top: 24px;
        }
      }
    }
    &__link {
      display: block;
      font-weight: bold;
      @include mq(touch) {
        padding: 6vw 0;
        color: #FFF;
        font-size: 3.6rem;
      }
      @include mq() {
        padding: 40px 0;
        font-size: 2.0rem;
        &:hover {
          text-decoration: underline;
        }
      }
      @include mq(lg) {
        padding: 0;
        color: #666;
        font-size: 1.1rem;
      }
    }
    &__ico {
      margin-left: 2px;
      fill: #FFF;
      vertical-align: top;
      @include mq() {
        vertical-align: middle;
      }
      @include mq(lg) {
        fill: #666;
      }
    }
  }
  &-lower {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    visibility: hidden;
    transform: translateX(50%);
    transition: .25s;
    opacity: 0;
    @include mq(lg) {
      display: none;
    }
    @at-root .nav-open & {
      & {
        visibility: visible;
        opacity: 1;
        transform: translateX(0);
        transition-delay: .6s;
      }
    }
    &__item {
      flex-basis: 50%;
      border-bottom: 1px solid #FFF;
      text-align: center;
      &:nth-child(2n) {
        border-left: 1px solid #FFF;
      }
      &--lg {
        flex-basis: 100%;
      }
    }
    &__link {
      display: block;
      padding: 4vw 0;
      color: #FFF;
      font-size: 3.0rem;
      @include mq() {
        padding: 20px;
        font-size: 1.6rem;
      }
    }
  }
  &-banner {
    margin-top: 0;
    @include mq() {
      margin-top: 30px;
    }
    a {
      transition: .3s;
      &:hover {
        opacity: .6;
        transition: .3s;
        @include mq(sp) {
          opacity: 1;
        }
      }
    }
    &-estimate {
      @include mq(lg) {
        display: none;
      }
      display: block;
      padding: 8vw 6.25vw;
      text-align: center;
      a {
        width: 100%;
        display: block;
      }
      img {
        width: 100%;
      }
    }
    &-pit {
      display: inline-block;
    }
    &-pitPc {
      @include mq(sp) {
        display: none;
      }
    }
    &-pitSp {
      display: none;
      @include mq(sp) {
        display: block;
      }
    }
    &-youtube {
      display: inline-block;
      margin-top: 16px;
      @include mq(touch) {
        margin: 0 0 0 40px;
      }
      @include mq(sp) {
        display: none;
      }
    }
  }
}
