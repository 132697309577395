$newsCategory: (
  bigtire:#4e9fbd,
  koshigaya:#3ab76f,
  yoshikawa:#134695,
  allshop:#cc0000,
  news:#faa002,
  tire:#000000,
);

.ico {
  &-label {
    display: inline-block;
    padding: 0 2.4vw;
    color: #FFF;
    font-size: 2.4rem;
    line-height: 1.7;
    text-align: center;
    vertical-align: middle;
    background: #cc0000;
    @include mq() {
      padding: 0 6px;
      font-size: 1.1rem;
      line-height: 1.6;
    }
    &--blue {
      @extend .ico-label;
      background-color: #134695;
    }
    &--green {
      @extend .ico-label;
      background-color: #3ab76f;
    }
    &--light-blue {
      @extend .ico-label;
      background-color: #4e9fbd;
    }
    &--red {
      @extend .ico-label;
      background-color: $red;
    }
    &--gray {
      @extend .ico-label;
      background-color: #666;
    }
  }
}
.news-list {
  &__item {
    display: table;
    width: 100%;
//    line-height: 1.6;
    & + & {
      margin-top: 25px;
    }
    & > div {
      display: table-cell;
      vertical-align: top;
      @include mq(sp) {
        display: block;
        width: 100%;
      }
    }
  }
  &__head {
    width: 28%;
    font-size: 2.4rem;
    @include mq() {
      width: 180px;
      font-size: 1.4rem;
    }
  }
  &__ico {
    display: inline-block;
    width: 10.4vw;
    margin-left: 2vw;
    color: #FFF;
    font-size: 1.6rem;
    text-align: center;
    vertical-align: middle;
    background: #e13c4c;
    @include mq() {
      width: 80px;
      margin-left: 20px;
      font-size: 1.1rem;
    }
    @include mq(sp) {
      width: 23vw;
      padding: 3px 0;
    }
    &--purple {
      background: #8957a1;
    }
    &--navy {
      background: #063f6e;
    }
    &--green {
      background: #1d7374;
    }
    &--red {
      background: #e13c4c;
    }
    &--yellow {
      background: #f7b801;
    }
  }
  &__txt {
    padding-left: 4.5%;
    @include mq() {
      padding-left: 40px;
    }
    @include mq(sp) {
      padding-left: 0;
    }
  }
}


.news {
  &-page-inner {
    padding-left: 50px;
    padding-right: 50px;
    @include mq(md) {
      max-width: 960px;
    }
    @include mq(sp) {
      padding-left: 3.2vw;
      padding-right: 3.2vw;
    }
  }
  &-metal-inner {
    padding-top: 64px;
    padding-bottom: 64px;
    background: #fff;
    @include mq(sp) {
      padding-top: 12vw;
      padding-bottom: 12vw;
    }
  }
  &-article {
    &__header {
      &__ttl {
        margin-top: 20px;
        font-size: 2.4rem;
        font-weight: bold;
        @include mq(sp) {
          margin-top: 5vw;
          font-size: 3.5rem;
        }
      }
    }
    &-sec {
      margin-top: 75px;
      @include mq(sp) {
        margin-top: 5vw;
      }
      &__ttl {
        padding-left: 20px;
        border-left: 3px #11299b solid;
        font-size: 1.8rem;
        @include mq(sp) {
          font-size: 3rem;
        }
      }
      &__txt {
        margin-top: 40px;
        @include mq(sp) {
          margin-top: 5vw;
        }
      }
    }
    &-photobox {
      margin-top: 40px;
      &-list {
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flex;
        display: -o-flex;
        display: flex;
        flex-wrap: wrap;
        &__caption {
          margin-top: 15px;
          color: #111;
          &:hover {
            text-decoration: underline;
          }
        }
        &__items {
          flex-basis: 31.4%;
          @include mq(sp) {
            flex-basis: 48%;
          }
          &:not(:nth-of-type(3n)) {
            @include mq(md) {
              margin-right: 2.9%;
            }

          }
          &:nth-of-type(even) {
            @include mq(sp) {
              margin-left: 2%;
            }
          }
          &:nth-of-type(n+4) {
            @include mq(md) {
              margin-top: 40px;
            }
          }
          &:nth-of-type(n+3) {
            @include mq(sp) {
              margin-top: 5%;
            }
          }
        }
      }
    }
  }
  &-modal {
    position: relative;
    padding: 20px;
    max-width: 740px;
    margin: 0 auto;
    background: #fff;
    .modal-close {
      position: absolute;
      top: -30px;
      right: 0;
      width: 23px;
      &:hover {
        cursor: pointer;
      }
    }
    .mfp-close {
//      display: none;
    }
    &-box {
      figure {
        text-align: center;
      }
      figcaption {
        text-align: left;
      }
    }
  }
}

.news {
  &-wrap {
    margin-top: 7.2vw;
    @include mq() {
      margin-top: 30px;
    }
  }
  &-list {
    &__item {
      padding-bottom: 5.33vw;
      border-bottom: 1px solid #e5e5e5;
      @at-root {
        .showa-news-list {
          &__items {
            border-bottom: none;
          }
        }
      }
      @include mq() {
        padding-bottom: 20px;
      }
      & + & {
        margin-top: 5.33vw;
        @include mq() {
          margin-top: 20px;
        }
      }
    }
    &__inner {
      @include mq() {
        display: table;
        width: 100%;
      }
    }
    a {
      color: $font-color-default;
      transition: $transition;
      &:hover {
        opacity: .3;
      }
    }
    &__cell {
      @include mq() {
        display: table-cell;
        vertical-align: top;
      }
    }
    &__date {
      @include mq() {
        width: 200px;
        overflow: hidden;
      }
    }
    &__shop-name {
      width: 24vw;
      margin-left: 3.33vw;
      line-height: 2.14;
      @include mq() {
        float: right;
        width: 90px;
        margin-top: 1px;
        margin-left: 0;
      }
    }
    &__txt {
      margin-top: 4vw;
      @include mq() {
        margin-top: 0;
        padding-left: 25px;
      }
    }
  }
  &-pager {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 32px;
    &__item {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 8px;
      flex-basis: 24px;
      max-width: 24px;
      height: 32px;
    }
    .page-numbers {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 8px;
      flex-basis: 24px;
      max-width: 24px;
      height: 32px;
      border: 1px solid #CCC;
      color: $font-color-default;
      &.next,&.prev {
        border: none;
        flex-basis: 7px;
        max-width: 7px;
          svg {
        fill: #CCC

            }
      }
      &.current {
        @extend .page-numbers;
        border-color: #11299b;
        background: #11299b;
        color: #FFF;
      }
    }
    a {
//      @extend .txt-fade;
    }
//    &__btn {
//      border: 1px solid #CCC;
//      color: $font-color-default;
//      &--current {
//        @extend .news-pager__btn;
//        border-color: $font-color-default;
//        background: $font-color-default;
//        color: #FFF;
//      }
//    }
  }
}

//ニュースタブ tire/modules/module.scssの中にも同じ記述があるが他への影響を考えてこちらにも記載
.cmn-nav {
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: -o-flex;
  display: flex;
  flex-wrap: wrap;
  margin-top: -3vw;
  margin-bottom: 12vw;
  @include mq() {
    margin-top: 0;
    margin-bottom: 70px;
  }
  &__item {
    flex-basis: 32%;
    margin-right: 2%;
    margin-top: 3vw;
    @include mq() {
      flex-basis: 22.9%;
      max-width: 22.9%;
      margin: 0;
      & + & {
        margin-left: 2%;
      }
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &--col04 {
      flex-basis: 48%;
      @extend .cmn-nav__item;
      @include mq() {
      flex-basis: 22.9%;
      max-width: 22.9%;

      }
      & + & {
        @include mq() {
        margin-left: 2.7%;
        }
      }
      &:nth-child(even) {
        @include mq(sp) {
          margin-right: 0;
        }
      }
      &:nth-child(3n) {
        @include mq(sp) {
          margin-right: 2%;
        }
      }
    }
    &--col03 {
      @extend .cmn-nav__item;
      @include mq() {
        flex-basis: 31.6%;
        max-width: 31.6%;
      }
      & + & {
        @include mq() {
        margin-left: 2.55%;
        }
      }
    }
  }
}

.btn {
  &-border-bottom {
    display: block;
    padding-bottom: 3px;
    color: #CCC;
    border-bottom: 2px solid #DDD;
    text-align: center;
    transition: $transition;
    &--active {
      @extend .btn-border-bottom;
      color: $red;
      border-bottom-color: $red;
    }
  }
}

//タブ切り替えのボタン色
@each $name, $color in $newsCategory {
    .active .btn-border--#{$name} {
      color: $color;
      border-color: $color;
    }
}
//昭和メタルニュースのカテゴリー色
@each $name, $color in $newsCategory {
    .news-list__ico--#{$name} {
      background: $color;
    }
}
//タイヤショップニュースのカテゴリー色
@each $name, $color in $newsCategory {
    .ico-label--#{$name} {
      background: $color;
    }
}

.single-news-page {
  @include mq() {
    .mfp-container {
      padding: 0 30px;
      max-width: 740px;
      left: 50%;
      transform: translateX(-50%);
      .mfp-arrow-left {
        left: -30px;
      }
      .mfp-arrow-right {
         right: -30px;
      }
    }
  }
//  @include mq(sp) {
//    .mfp-arrow {
//      margin-top: 0;
//      transform: translateY(-50%) scale(0.6);
//    }
//    .mfp-arrow-left {
//      left: -10px;
//    }
//    .mfp-arrow-right {
//      right: -10px;
//    }
//  }

}



