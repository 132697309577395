.contact {
  &-store {
    border: 1px solid $base-blue;
    color: $font-color-default;
    &__content {
      position: relative;
    }
    &__img {
      @include mq() {
        position: absolute;
        top: 0;
        right: 0;
        width: 60%;
        height: 100%;
        background-size: cover;
        background-position: left center;
        background-repeat: no-repeat;
        background-image: url(../img/sellcar/img_store_outside_pc.jpg);
        img {
          display: none;
        }
        @at-root .used & {
          width: 60.4%;
          background-image: url(../img/usedparts/img_store_outside_pc.jpg);
        }
      }
    }
    &__ttl {
      padding: .9375vw 0;
      background: $base-blue;
      color: #FFF;
      font-size: 4.0rem;
      text-align: center;
      @include mq() {
        padding: 5px 0;
        font-size: 2.0rem;
      }
    }
    &__name {
      margin-bottom: 2.6vw;
      color: $base-blue;
      font-size: 4.5rem;
      @include mq() {
        margin-bottom: 7px;
        font-size: 2.4rem;
      }
    }
    &__txt {
      position: relative;
      padding: 4.7vw 6.25vw 6.25vw;
      line-height: 1.88;
      z-index: 1;
      @include mq() {
        padding: 27px 40px 40px;
        font-size: 1.6rem;
      }
    }
    &__btn {
      @include mq() {
        text-align: left;
      }
    }
  }
}