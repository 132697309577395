.form-table {
  @include table-cmn;
}
.form {
  &-area {
    @include mq() {
      display: inline-block;
      width: 100%;
      max-width: 265px;
    }
    @include mq(lg) {
      max-width: 440px;
    }
  }
  &-example {
    margin-top: 1.2vw;
    @include mq() {
      display: inline-block;
      margin: 0 0 0 15px;
      vertical-align: middle;
    }
    @include mq(lg) {
      margin-left: 30px;
    }
  }
  @mixin form-input {
    width: 100%;
    padding: 2vw;
    border: 1px solid #CCC;
    vertical-align: bottom;
    @include mq() {
      padding: 3px 7px;
      font-size: 1.4rem;
    }
  }
  &-input {
    @include form-input;
    &--sm {
      width: 100px;
    }
    &--xs {
      width: 60px;
    }
  }
  &-txtarea {
    @include form-input;
    max-width: 664px;
    height: 40vw;
    resize: none;
    @include mq() {
      height: 80px;
    }
  }
  &-select {
    @include form-input;
    width: auto;
    padding-right: 13vw;
    background: #FFF url(../img/common/ico_form_select.png) right center / contain no-repeat;
    @include mq() {
      padding: {
        right: 58px;
        left: 10px;
      };
    }
    &::-ms-expand {
      //IE用矢印非表示
      display: none;
    }
  }
  &-btn {
    //チェックボックスやラジオボタン
    margin-top: -.5vw;
    margin-right: 3vw;
    vertical-align: middle;
    @include mq() {
      margin-top: -2px;
      margin-right: 10px;
    }
  }
  &-labels {
    &__item {
      margin-right: 5vw;
      @include mq() {
        margin-right: 30px;
      }
    }
  }
  &-birth {
    display: table;
    width: 100%;
    max-width: 325px;
    &__item {
      display: table-cell;
      margin-right: 2vw;
      @include mq() {
        margin-right: 20px;
      }
      .form-input--sm {
        @include mq() {
          max-width: 70px;
        }
      }
      .form-input--xs {
        @include mq() {
          max-width: 50px;
        }
      }
      .form-input--sm,
      .form-input--xs {
        @include mq(lg) {
          max-width: none;
        }
      }
    }
    &__unit {
      margin-left: 2vw;
      @include mq() {
        margin-left: 4px;
      }
      @include mq(lg) {
        margin-left: 9px;
      }
    }
  }
  &-times {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-wrap: wrap;
    & + & {
      margin-top: 5vw;
      @include mq() {
        margin-top: 10px;
      }
    }
    &__item {
      & + & {
        margin: 3vw 0 0 0;
        @include mq() {
          margin: 0 0 0 17px;
        }
      }
    }
    &__unit {
      margin-left: 2vw;
      @include mq() {
        margin-left: 7px;
      }
    }
  }
  &-images {
    &__unit {
      margin-right: 5px;
    }
    & + & {
      margin-top: 10px;
    }
  }
  &-btn-list {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    justify-content: center;
    margin-top: 6vw;
    @include mq() {
      margin-top: 40px;
    }
    &__item {
      & + & {
        margin-left: 6vw;
        @include mq() {
          margin-left: 40px;
        }
      }
    }
  }
  &-txt {
    margin-top: .5em;
    font-size: 90%;
  }
}