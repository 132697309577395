.top {
  &-mv {
    position: relative;
    &__bnr {
      display: none;
      a:hover {
        opacity: .7;
      }
      @include mq() {
        display: block;
        position: absolute;
        top: 54%;
        right: 0;
        padding-right: 5%;
      }
      @include mq(xl) {
        top: 50%;
      }
    }
    &-slider {
      &__item {
        height: 85.4vw;
        background-size: cover;
        background-position: center bottom;
        vertical-align: bottom;
        @include mq() {
          height: 650px;
        }
        @include mq(xl) {
          height: 100vh;
          min-height: 850px;
        }
      }
    }
    //スライダー背景画像は「mv_$$_pc.jpg」のような形で命名し、下記mixinで出力
    @mixin mv-img($src) {
      background-image: url(../img/top/#{$src}_sp.jpg);
      @include mq() {
        background-image: url(../img/top/#{$src}_pc.jpg);
      }
    }
    &01 {
      @include mv-img(mv_01);
      &__txt {
        position: absolute;
      }
      &__txt {
        top: 29.8%;
        left: 0%;
        width: 100%;
        padding-right: 5.2vw;
        color: #FFF;
        font-size: 4.0rem;
        text-align: right;
        text-shadow: 0 0 1px #5b8bca,
                     0 0 3px #5b8bca,
                     0 0 7px #5b8bca;
        font-family: "Yu Mincho", "YuMincho", 'Noto Serif Japanese', serif;
        font-weight: 600;
        line-height: 1.75;
        @include mq() {
          top: 35.8%;
          padding-right: 5%;
          font-size: 2.8rem;
          text-shadow: 0 0 5px #5b8bca,
                       0 0 7px #5b8bca,
                       0 0 7px #5b8bca;
        }
      }
    }
    &02 {
      @include mv-img(mv_02);
      position: relative;
      a {
        position: absolute;
        inset: 0;
        color: transparent;
        user-select: none;
      }
    }
    &03 {
      @include mv-img(mv_03);
      position: relative;
      a {
        position: absolute;
        inset: 0;
        color: transparent;
        user-select: none;
      }
    }
    &04 {
      @include mv-img(mv_04);
      position: relative;
      a {
        position: absolute;
        inset: 0;
        color: transparent;
        user-select: none;
      }
    }
    &05 {
      @include mv-img(mv_05);
      position: relative;
      a {
        position: absolute;
        inset: 0;
        color: transparent;
        user-select: none;
      }
    }
    &06 {
      @include mv-img(mv_06);
      position: relative;
      a {
        position: absolute;
        inset: 0;
        color: transparent;
        user-select: none;
      }
    }
    &07 {
      @include mv-img(mv_07);
      position: relative;
      a {
        position: absolute;
        inset: 0;
        color: transparent;
        user-select: none;
      }
    }
    &-arrow {
      position: absolute;
      display: block;
      bottom: 3.75vw;
      left: 50%;
      font-size: 1.4rem;
      color: #FFF;
      text-align: center;
      z-index: 10;
      transform: translateX(-50%);
      @include mq() {
        bottom: 26px;

      }
      &__txt {
        display: block;
        margin-bottom: .1vw;
        @include mq() {
          margin-bottom: 10px;
        }
      }
      &__bar {
        position: relative;
        display: block;
        width: 1px;
        height: 3.125vw;
        margin: 0 auto;
        overflow: hidden;
        @include mq() {
          height: 30px;
        }
        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: #FFF;
          content: '';
          animation: arrow-scroll 1.5s infinite;
          @keyframes arrow-scroll {
            0% {
              transform: translateY(-100%);
            }
            100% {
              transform: translateY(100%);
            }
          }
        }
      }
    }
  }
  &-announce {
    margin-top: 30px;
    padding: 10px 0;
    font-size: 3rem;
    font-family: "Yu Mincho", "YuMincho";
    font-weight: bold;
    @include mq() {
      font-size: 1.6rem;
    }
  }
  &-intro {
    @extend .clearfix;
    padding: 0 3.125vw;
    @include mq() {
      max-width: 700px;
      margin: 0 auto;
    }
    @include mq(lg) {
      max-width: none;
    }
    &__bnr {
      margin-bottom: 8vw;
      @include mq() {
        display: none;
      }
      &--estimate {
        width: 100%;
        margin: 0 auto 8vw;
        @include mq(lg) {
          width: 700px;
          margin: 0 auto 30px;
        }
        a {
          display: block;
          width: 100%;
          transition: .3s;
          &:hover {
            opacity: .6;
            transition: .3s;
            @include mq(sp) {
              opacity: 1;
            }
          }
        }
      }
      &--estimate-large {
        width: 100%;
        margin: 0 auto 8vw;
        @include mq(lg) {
          width: 960px;
          margin: 0 auto 30px;
        }
      }
      &--recruit {
        width: 100%;
        @include mq(lg) {
          width: 700px;
          margin: 0 auto;
        }
        a {
          display: block;
          width: 100%;
          transition: .3s;
          &:hover {
            opacity: .6;
            transition: .3s;
            @include mq(sp) {
              opacity: 1;
            }
          }
        }
      }
      a {
        display: block;
      }
    }
    &__ttl {
      margin-bottom: 4vw;
      font-size: 4.5rem;
      text-align: center;
      line-height: 1.5;
      @include mq() {
        margin-bottom: 28px;
        font-size: 3.0rem;
      }
      @include mq(lg) {
        float: left;
        width: 45%;
        text-align: left;
      }
      small {
        display: block;
        font-size: 3.2rem;
        line-height: 1.6;
        @include mq() {
          margin-top: 20px;
          font-size: 1.6rem;
          br {
            display: none;
          }
        }
      }
    }
    &__img {
      width: 86.04vw;
      margin: 0 auto;
      @include mq() {
        max-width: 500px;
      }
      @include mq(lg) {
        width: 55%;
        max-width: none;
        float: right;
        padding-left: 30px;
      }
    }
    &__txt {
      margin-bottom: 10vw;
      @include mq(lg) {
        float: left;
        width: 45%;
        margin-bottom: 60px;
      }
      p {
        margin-top: 4vw;
        @include mq(lg) {
          margin-top: 18px;
        }
      }
    }
    &__btn {
      @include mq(lg) {
        text-align: left;
      }
    }
  }
  &-nav {
    &__link {
      position: relative;
      display: block;
      height: 20.8vw;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      @include mq() {
        height: auto;
        &:before {
          display: block;
          padding-top: 15%;
          content: '';
        }
      }
      & + & {
        margin-top: 1px;
      }
    }
    &__txt {
      position: absolute;
      color: #FFF;
      font-size: 6.0rem;
      line-height: 20.8vw;
      @include mq() {
        top: 50%;
        margin-top: -20px;
        font-size: 4.0rem;
        line-height: 40px;
      }
      small {
        font-size: 4.0rem;
        vertical-align: bottom;
        @include mq() {
          font-size: 2.4rem;
        }
      }
      &:before {
        position: absolute;
        color: rgba(255, 255, 255, 0.08);
        font-size: 14.0rem;
        font-family: $font-family-advent;
        font-weight: bold;
        content: attr(data-txt);
        letter-spacing: .04em;
        @include mq() {
          font-size: 12.0rem;
        }
      }
      @mixin float-txt($direction) {
        #{$direction}: 5.7vw;
        @include mq() {
          #{$direction}: 50px;
        }
        &:before {
          #{$direction}: -2vw;
          @include mq() {
            #{$direction}: -30px;
          }
        }
      }
      @at-root .top-nav__link:nth-child(odd) & {
        @include float-txt(left);
      }
      @at-root .top-nav__link:nth-child(even) & {
        @include float-txt(right);
      }
    }
  }
  &-news {
    background: url(../img/top/bg_news_sp.png) center center / cover no-repeat;
  }
}
