
//ブレイクポイントをマップ型で定義
$breakpoints: (
  'sp': 'screen and (max-width: 767px)',//スマホ
  'touch': 'screen and (max-width: 1024px)',//タブレット縦～横
  'sm': 'screen and (min-width: 400px)',
  'md': 'screen and (min-width: 768px)',
  'lg': 'screen and (min-width: 1025px)',
  'xl': 'screen and (min-width: 1280px)',
) !default;

$font-color-default: #111;

$font-family-default: "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック体", YuGothic, sans-serif;

$font-family-advent: 'Advent Pro', sans-serif;

$font-sawarabi: "Sawarabi Mincho";

$transition: .3s;


//color
$base-blue: #11299b;
$red: #cc0000;