$mega-menu-width: 210px;
body {
  &:before {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(17,41,155,.9);
    content: '';
    transition: $transition;
    transform: translateY(-10%);
    opacity: 0;
    visibility: hidden;
    z-index: 100;
    @at-root .nav-open:before {
      transform: translateY(0);
      opacity: 1;
      visibility: visible;
    }
    @include mq(lg) {
      left: 200px;
      width: $mega-menu-width;
      transform: translateX(-10%);
      @at-root .megaMenuOpen:before {
        transform: translateX(0);
        opacity: 1;
        visibility: visible;
      }
    }
  }
}
.mega-menu {
  position: relative;
  &__parent {
    @include mq(touch) {
      position: relative;
      &:before,
      &:after {
        position: absolute;
        top: 50%;
        right: 8.54vw;
        width: 3.75vw;
        height: 2px;
        margin-top: -1px;
        background: #FFF;
        transition: $transition;
        content: '';
      }
      &:after {
        transform: rotate(90deg);
        @at-root .megaMenuOpen:after {
          transform: rotate(0);
        }
      }
    }
    @include mq() {
      &:before,
      &:after {
        right: 20px;
        width: 20px;
      }
    }
  }
  &__child {
    @include mq(touch) {
      display: none;
    }
    @include mq(lg) {
      display: block !important;
      position: absolute;
      top: 0;
      left: 100%;
      width: $mega-menu-width;
      opacity: 0;
      transform: translateX(-10%);
      visibility: hidden;
      transition: $transition;
      @at-root .mega-menu:hover & {
        transform: translateX(0);
        visibility: visible;
        opacity: 1;
      }
      @include mq(touch) {
        display: none;
      }
    }
  }
  &__item {
    @include mq(touch) {
      border-top: 1px solid rgba(255,255,255,.2);
    }
  }
  &__link {
    display: block;
    color: #FFF;
    font-weight: bold;
    @include mq(touch) {
      padding: 4vw 10vw;
      font-size: 3.0rem;
    }
    @include mq() {
      padding: 20px 40px;
      font-size: 1.6rem;
    }
    @include mq(lg) {
      padding: 11px 20px;
      font-size: 1.3rem;
      line-height: 1.5;
      transition: $transition;
      &:hover {
        background: $base-blue;
      }
    }
    br {
      display: none;
      @include mq(lg) {
        display: block;
      }
    }
  }
  &__ico {
    margin-left: 3px;
    margin-top: -4px;
    fill: #FFF;
  }
}