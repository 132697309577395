.ttl {
  &-cmn-01 {
    //見出し1
    @extend .font-advent;
    margin-bottom: 5.2vw;
    font-size: 4.5rem;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    @include mq() {
      margin-bottom: 40px;
      font-size: 3.0rem;
    }
    &__sm {
      display: block;
      margin-top: 3.125vw;
      color: $base-blue;
      font-size: 3.0rem;
      font-weight: normal;
      @include mq() {
        margin-top: 15px;
        font-size: 1.4rem;
      }
    }
  }
  &-primary {
    //下層ページのメイン見出し
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center;
    background-size: cover;
    background-position: center center;
    height: 35vw;
    padding: {
      left: 10vw;
      right: 5vw;
    };
    color: #FFF;
    font-size: 6.2rem;
    overflow: hidden;
    @include mq() {
      height: 20vw;
      padding: {
        left: 50px;
        right: 50px;
      };
      font-size: 4.0rem;
    }
    &--right {
      justify-content: flex-end;
      padding: {
        left: 5vw;
        right: 10vw;
      };
      @include mq() {
        padding: {
          left: 50px;
          right: 50px;
        };
      }
    }
    small {
      font-size: 60%;
      vertical-align: middle;
    }
    &__inner {
      position: relative;
      display: block;
      &:before {
        position: absolute;
        left: -5vw;
        top: 50%;
        color: rgba(255, 255, 255, 0.08);
        font-weight: bold;
        font-size: 210%;
        font-family: $font-family-advent;
        white-space: nowrap;
        transform: translateY(-50%);
        letter-spacing: .04em;
        content: attr(data-txt);
        @include mq() {
          left: -25px;
          font-size: 300%;
        }
        @at-root .ttl-primary--right & {
          left: auto;
          right: -5vw;
          @include mq() {
            right: -25px;
          }
        }
      }
    }
  }
  &-border-bottom {
    @extend .clearfix;
    margin-top: 12vw;
    margin-bottom: .23em;
    padding-bottom: .45em;
    color: $base-blue;
    border-bottom: 5px solid $base-blue;
    font-size: 4.0rem;
    line-height: 1;
    @include mq() {
      margin-top: 40px;
      font-size: 2.2rem;
    }
    &__txt-float {
      float: right;
      margin-top: .3em;
      font-size: 70%;
    }
  }
  &-blue {
    margin: 5.2vw 0 2vw;
    color: $base-blue;
    font-size: 4.0rem;
    text-align: center;
    @include mq() {
      margin: 28px 0 8px;
      font-size: 2.2rem;
    }
  }
}