

.btn {
  &-cmn {
    display: inline-block;
    width: 31.25vw;
    padding: 2vw 0;
    background: $base-blue;
    border: 1px solid $base-blue;
    color: #FFF;
    font-size: 4.0rem;
    transition: $transition;
    text-align: center;
    cursor: pointer;
    @include mq() {
      width: 100px;
      padding: 3px 0;
      font-size: 1.4rem;
    }
    &:hover {
      background: #FFF;
      color: $base-blue;
    }
    &--gray {
      background: #AAA;
      border-color: #AAA;
      &:hover {
        background: #AAA;
        color: #FFF;
      }
    }
    &--lg {
      width: 70vw;
      font-size: 4.5rem;
      @include mq() {
        width: 250px;
        font-size: 1.8rem;
      }
    }
    &--size-free {
      width: auto;
      padding: {
        left: 7vw;
        right: 7vw;
      };
      @include mq() {
        padding: {
          left: 30px;
          right: 30px;
        };
      }
    }
  }
  &-radius {
    @mixin btn-radius-color($border-color,$bg-color) {
      background: $border-color;
      border: 1px solid $bg-color;
      &:hover {
        background: #FFF;
        color: $bg-color;
        svg {
          fill: $bg-color;
        }
      }
    }
    @include btn-radius-color($base-blue,$base-blue);
    display: block;
    width: 100%;
    max-width: 60vw;
    margin: 0 auto;
    padding: 3vw 0;
    border-radius: 10vw;
    background: $base-blue;
    color: #FFF;
    text-align: center;
    transition: $transition;
    @include mq() {
      max-width: 200px;
      margin: 0;
      padding: 2px 0;
      font-size: 1.5rem;
    }
    &--small {
      @include mq() {
        max-width: 180px;
        font-size: 1.3rem;
      }
    }
    &__ico {
      fill: #FFF;
      transition: $transition;
    }
    &--light-blue {
      @include btn-radius-color(#526be2,#526be2);
    }
    &--midium-blue {
      @include btn-radius-color(#314ABE,#314ABE);
    }
  }
  &-area {
    margin-top: 6.25vw;
    text-align: center;
    @include mq() {
      margin-top: 35px;
    }
  }
  &-col2 {
    @include mq() {
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
    }
    &__item {
      @include mq() {
        flex-basis: 300px;
        max-width: 300px;
      }
      & + & {
        margin-top: 3vw;
        @include mq() {
          margin: 0 0 0 30px;
        }
      }
    }
  }
}




